'use client';

import { useEffect } from 'react';

declare global {
  interface Window {
    googletag: { cmd: [] } | any;
    apstag: any;
    dataLayer: Record<string, any>[];
  }
}

export default function GoogleAdsInitialConf() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.googletag && googletag.apiReady) {
        window.googletag = window.googletag || { cmd: [] };

        const { googletag } = window;
        googletag.cmd.push(() => {
          const REFRESH_KEY = 'refresh';
          const REFRESH_VALUE = 'true';
          const SECONDS_TO_WAIT_AFTER_VIEWABILITY = 20;
          googletag.companionAds().setRefreshUnfilledSlots(true);
          googletag.pubads().enableSingleRequest();
          googletag.pubads().enableVideoAds();
          googletag.pubads().collapseEmptyDivs();
          googletag.pubads().enableLazyLoad({
            fetchMarginPercent: 600,
            renderMarginPercent: 300,
            mobileScaling: 2.0,
          });

          googletag.pubads().addEventListener('impressionViewable', (event: { slot: googletag.Slot | null }) => {
            const { slot } = event;
            if (!slot) return;
            if (slot.getTargeting(REFRESH_KEY).indexOf(REFRESH_VALUE) > -1) {
              setTimeout(() => {
                if (slot.getTargeting('refreshed_slot')) {
                  slot.setTargeting('refreshed_slot', 'true');
                }
                googletag.pubads().refresh([slot]);
              }, SECONDS_TO_WAIT_AFTER_VIEWABILITY * 1000);
            }
          });

          googletag.pubads().disableInitialLoad();
          googletag.enableServices();
        });
      }
    }
  }, []);

  return <span></span>;
}
