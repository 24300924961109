import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.19_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.82.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.19_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.82.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"preload\":true}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Google/Ads/GoogleAdsInitialConf.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Google/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleTagManagerHeadScript","GoogleTagManagerBodyScript"] */ "/app/src/components/Google/GoogleTagManagerHeadScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Navbar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TransitioningProvider"] */ "/app/src/hooks/useTransition.tsx");
