'use client';

import { usePathname } from 'next/navigation';
import { useState, createContext, ReactNode, useContext, useEffect } from 'react';
import { PageViewGTM } from '@/lib/gtm';
import dynamic from 'next/dynamic';

interface TransitioningProviderProps {
  children: ReactNode;
}

interface TransactionsContextData {
  isTransitioning: boolean;
  handleTransitioning: (url: string) => void;
}

const TransitioningContext = createContext({} as TransactionsContextData);

export function TransitioningProvider({ children }: TransitioningProviderProps) {
  const AdsGeneratorMemo = dynamic(() => import('@/components/Google/Ads/AdsTypeGeneratorsMemo'), { ssr: false });

  const [isTransitioning, setIsTransitioning] = useState(false);
  const pathname = usePathname();

  const handleTransitioning = (url: string) => {
    PageViewGTM(url);

    if (typeof window !== 'undefined') {
      const googletag = window.googletag;

      if (window.googletag && googletag.apiReady) {
        googletag.cmd.push(() => {
          googletag.destroySlots();
        });
      }
    }

    setIsTransitioning(false);
  };

  useEffect(() => {
    handleTransitioning(pathname);
  }, [pathname]);

  return (
    <TransitioningContext.Provider value={{ isTransitioning, handleTransitioning }}>
      <AdsGeneratorMemo type="cto/superLeaderboard/footer" />
      <AdsGeneratorMemo type="cto/anchor" />
      {children}
    </TransitioningContext.Provider>
  );
}

export function useTransition() {
  const context = useContext(TransitioningContext);

  return context;
}
