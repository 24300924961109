'use client';

import Image from 'next/image';
import PageWrapper from '../Layout/PageWrapper';
import ctlogo from '_/logo-ct-ofertas.svg';
import SearchBar from './SearchBar/SearchBar';
import Link from 'next/link';
import { classNames } from '@/utils';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { icons } from '@iconify-json/mdi';
import { getIcons } from '@iconify/utils';

type ItemNavbar = {
  href: string;
  target: string;
  text: string;
};

function TopBar() {
  return (
    <div className="w-full py-3 border-b-[1px] border-b-[rgba(255,255,255,.2)]">
      <PageWrapper>
        <div className="flex flex-row flex-wrap box-border justify-center md:justify-between text-white">
          <Link className="no-underline text-white self-start md:self-center" href="/" title="Canaltech Ofertas">
            <Image priority height={38} width={0} src={ctlogo} alt="Canaltech Ofertas" className="mb-2 md:mb-0" unoptimized />
          </Link>
          <SearchBar />
        </div>
      </PageWrapper>
    </div>
  );
}

function BottomBar({ pathName }: { pathName: string }) {
  const arrayBar = [
    {
      href: '/',
      target: '_self',
      text: 'Últimas',
    },
    {
      href: '/top/',
      target: '_self',
      text: 'TOP Ofertas',
    },
    {
      href: '/cupom-de-desconto/',
      target: '_self',
      text: 'Cupom de Desconto',
    },
    {
      href: '/lojas/',
      target: '_self',
      text: 'Lojas',
    },
    {
      href: '/marcas/',
      target: '_self',
      text: 'Marcas',
    },
    {
      href: '/categorias/',
      target: '_self',
      text: 'Categorias',
    },
    {
      href: 'https://ofertas.canaltech.com.br/app-ct-ofertas/',
      target: '_blank',
      rel: 'noreferrer',
      text: 'App CT Ofertas',
    },
  ];

  const defaultMenu = {
    href: '/',
    target: '_self',
    text: 'Últimas',
  };

  const gpObject = {
    href: 'https://ofertas.canaltech.com.br/grupos-de-oferta/',
    target: '_blank',
    rel: 'noreferrer',
    text: 'Grupo de Ofertas',
  };

  const objStartItemMenu = arrayBar.find(itemNav => pathName === itemNav.href) || defaultMenu;

  const [activeMenu, setActiveMenu] = useState(objStartItemMenu);
  const [isOpen, setOpen] = useState(false);

  function clickDropDown(itemNav: ItemNavbar) {
    if (itemNav?.target !== '_blank') setActiveMenu(itemNav);
    setOpen(false);
  }

  function toogleMenu() {
    setOpen(!isOpen);
  }

  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  const reducedIconSet = getIcons(icons, ['chevron-down']);

  return (
    <PageWrapper>
      <div className="relative flex flex-row flex-wrap box-border text-white md:px-0 tems-center">
        <button
          onClick={() => toogleMenu()}
          className={classNames(
            pathName === activeMenu.href
              ? `text-white after:block after:absolute after:bottom-0 after:left-0 after:w-full after:h-[3px] lg:justify-center after:bg-white after:content-[''] after:rounded-[3px]`
              : 'text-[rgba(255,255,255,.6)]',
            `flex flex-row relative items-center text-sm leading-10 h-[40px] lg:hidden`
          )}>
          {activeMenu.text}
          {reducedIconSet && (
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              dangerouslySetInnerHTML={{ __html: reducedIconSet.icons['chevron-down'].body.toString() }}
              className={classNames('ml-1 text-white transition-all duration-300', isOpen ? 'rotate-180' : '')}
            />
          )}
        </button>

        <ul
          className={classNames(
            `${!isOpen && 'hidden'} lg:flex`,
            'flex flex-col absolute top-10 bg-white z-10 transition-all rounded shadow',
            'lg:flex lg:flex-row lg:relative lg:h-[40px] lg:top-0 lg:bg-transparent lg:rounded-none lg:shadow-none'
          )}>
          {arrayBar.map((itemNav, idx) => {
            return (
              <li key={idx} className={classNames(pathName === itemNav.href && `border-l-2 border-gray-800 lg:border-l-0`, idx > 0 && 'lg:ml-5')}>
                <a
                  onClick={() => clickDropDown(itemNav)}
                  className={classNames(
                    'flex relative items-center w-[240px] border-b border-gray-200 px-4 h-[50px] m-0 text-gray-500 text-sm align-middle list-none',
                    'after:block after:absolute after:bottom-0 after:left-0 after:w-full after:h-[3px] after:content-[""] after:rounded-[3px] after:opacity-0',
                    'hover:after:block hover:after:absolute hover:after:bottom-0 hover:after:left-0 hover:after:w-full hover:after:h-[3px] hover:after:content-[""] hover:after:rounded-[3px] hover:after:opacity-100',
                    'lg:after:bg-white lg:justify-center lg:w-full lg:h-[40px] lg:px-0 lg:border-b-0 lg:text-[rgba(255,255,255,.6)]',
                    pathName === itemNav.href && `text-gray-800 lg:text-white after:opacity-100`
                  )}
                  href={itemNav.href}
                  target={itemNav.target}
                  rel={itemNav?.rel}>
                  {itemNav.text}
                </a>
              </li>
            );
          })}
        </ul>

        <ul className={classNames('flex flex-row relative h-[40px] top-0 bg-transparent rounded-none shadow-none')}>
          <li className="pl-4">
            <a
              onClick={() => clickDropDown(gpObject)}
              className={classNames(
                `flex relative text-sm text-white items-center list-none hover:after:block hover:after:absolute hover:after:bottom-0 hover:after:left-0 hover:after:w-full hover:after:h-[3px] hover:after:content-[''] hover:after:rounded-[3px] hover:after:opacity-100
                    after:bg-white justify-center w-full h-[40px] px-0 border-b-0 ml-0 lg:text-[rgba(255,255,255,.6)]`
              )}
              href={gpObject.href}
              target={gpObject.target}
              rel={gpObject?.rel}>
              {gpObject.text}
            </a>
          </li>
        </ul>
      </div>
    </PageWrapper>
  );
}

export default function NavBar() {
  const pathname = usePathname();

  return (
    <nav className="fixed top-0 left-0 w-full z-50 bg-primary">
      <TopBar />
      <BottomBar pathName={pathname} />
    </nav>
  );
}
