import React, { ReactNode } from 'react';
interface PageWrapperProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export default function PageWrapper({ children, className = '' }: PageWrapperProps) {
  return (
    <div
      className={`relative block mx-auto items-center min-height-[100vh] sm:max-w-[100vw] md:max-w-[46rem] lg:max-w-[64rem] xl:max-w-[71rem] px-4 ${className}`}>
      {children}
    </div>
  );
}
