'use client';
import { classNames } from '@/utils';
import Link from 'next/link';
import React, { ChangeEvent, useEffect, useState } from 'react';

interface SearchData {
  categoria: string[][];
  produto: string[][];
  marca: string[][];
  loja: string[][];
}

const defaultData = {
  categoria: [],
  produto: [],
  marca: [],
  loja: [],
};

type ResultsType = { titleQuery: string; results: Array<string[]> }[];

export default function SearchBar() {
  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchData, setSearchData] = useState<SearchData>(defaultData);
  const [results, setResults] = useState<ResultsType>([]);
  const [hasError, setHasError] = useState(false);
  const [areAllResultsEmpty, setAreAllResultsEmpty] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);

  const blocks = [
    {
      tipo: 'Categoria',
    },
    {
      tipo: 'Produto',
    },
    {
      tipo: 'Marca',
    },
    {
      tipo: 'Loja',
    },
  ];

  useEffect(() => {
    const getJsonSearchData = async () => {
      try {
        const response: any = await fetch(`${process.env.NEXT_PUBLIC_URL}busca.json`, { method: 'GET', cache: 'no-cache' });
        const data = await response.json();

        setSearchData(data);
      } catch (error) {
        setHasError(true);
      }
    };

    getJsonSearchData();
  }, []);

  const autocompleteMatch = (input: string, category: string) => {
    setIsSearching(true);

    if (input == '') {
      return [];
    }

    const reg = new RegExp(input.toLowerCase(), 'gi');

    const matches = searchData[
      category.toLowerCase() as keyof {
        categoria: string[][];
        produto: string[][];
        marca: string[][];
        loja: string[][];
      }
    ].filter((term: string[]) => {
      const elementDisplayName = 0;

      if (term[elementDisplayName].match(reg)) {
        return term;
      }
    });

    return matches;
  };

  const handleSearch = (value: string) => {
    setSearch(value);

    const results: ResultsType = [];

    if (value?.length < 2) return;

    blocks.map(block => {
      results.push({
        titleQuery: block.tipo,
        results: autocompleteMatch(value, block.tipo),
      });
    });

    const areAllResultsEmpty = results.every(item => item.results?.length === 0);
    setAreAllResultsEmpty(areAllResultsEmpty);
    results.sort((a, b) => a.results?.length - b.results?.length);
    setResults(results);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
  };
  const handleOnBlur = () => {
    setIsSearching(false);
  };

  const handleOnFocus = () => {
    setIsSearching(true);
  };

  return (
    <div className="lg:w-[65%] md:w-[60%] w-full relative">
      <input
        id="pesquisa-ofertas"
        autoComplete="off"
        type="search"
        placeholder={!hasError ? 'Procure por categoria, produto, marca ou loja' : 'Algo deu errado, tente novamente mais tarde'}
        autoCapitalize="off"
        disabled={hasError}
        className="inline-block
        h-[38px]
        w-full
        rounded
        bg-transparent
        bg-gradient-to-r from-[#22222233] to-[#2222221a]
        text-white
        placeholder-gray-300
        text-sm
        leading-9
        indent-4
        pr-9
        border-none
        m-0
        outline-none
        shadow-none
        "
        value={search}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
      />
      <button
        className="text-2xl
        leading-4
        absolute
        h-[38px]
        w-[38px]
        right-0
        top-0
        bg-transparent
        border-0
        text-center
        m-0
        p-0
        "
        type="submit">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 m-0">
          <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
        </svg>
      </button>
      {search?.length > 0 && isSearching && (
        <div className="bg-white absolute w-full shadow-sm max-h-[500px] rounded-[4px] overflow-x-hidden overflow-y-auto z-[500] divide-y">
          {areAllResultsEmpty && (
            <div className="p-4 text-black text-sm">
              <span>Nenhum resultado encontrado...</span>
            </div>
          )}
          {results.map((item, sectionId: number) => (
            <div key={sectionId + item.titleQuery}>
              {item.results?.length > 0 && (
                <div key={sectionId + new Date().getTime()} className={'p-4'}>
                  {item.results?.length > 0 && (
                    <>
                      <div key={sectionId} className=" font-medium text-sm mb-4">
                        {item.titleQuery !== 'Produto' ? (
                          <>
                            <div className="flex justify-between">
                              <span className=" text-[#a2a2a2]">{item.titleQuery}</span>
                              <Link className=" text-[#a2a2a2] hover:underline" href={'/' + item.titleQuery.toLocaleLowerCase() + 's'} onClick={handleOnBlur}>
                                Ver todas
                              </Link>
                            </div>
                          </>
                        ) : (
                          <span className="text-[#a2a2a2]">{item.titleQuery}</span>
                        )}
                      </div>
                      <ul>
                        {item.results.map((result, index) => {
                          return (
                            <li
                              key={index + new Date().getTime() + Math.random() * 1000}
                              className={classNames(
                                'text-black text-sm rounded-sm block text-left hover:bg-bg',
                                sectionId === currentSection && index === currentIndex && 'bg-bg'
                              )}>
                              {result[0] && (
                                <Link
                                  href={result[1]}
                                  onClick={handleOnBlur}
                                  className="w-full h-full py-2 px-4 block"
                                  dangerouslySetInnerHTML={{
                                    __html: result[0].replace(new RegExp(search, 'gi'), (match: string) => `<span class='font-semibold'>${match}</span>`),
                                  }}
                                />
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
