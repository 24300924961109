export function classNames(...classes: unknown[]): string {
  return classes.filter(Boolean).join(' ');
}

export async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

export function isExpired(endDate: string) {
  if (!endDate || endDate?.length < 19) return false;
  let [day, month, year, hour, minute, second]: number[] = endDate.split(/[\s/:\-]+/).map(Number);
  var dataAlvo = new Date(year, month - 1, day, hour, minute, second);
  var dataAtual = new Date();

  var diferenca = dataAlvo.getTime() - dataAtual.getTime();

  return diferenca < 0;
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export default function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export const customStylesModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-40%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    padding: '0px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '1000',
  },
};

export const formatToReal = (value: number) => {
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};
