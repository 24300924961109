'use client';

import { PageViewGTM } from '@/lib/gtm';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

export default function Analytics() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (pathname) {
      PageViewGTM(pathname);
    }
  }, [pathname, searchParams]);

  return null;
}
